/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Global.scss o specifico al componente */
@media(min-width: 768px){
  ion-modal.custom-modal {
    --width: 95%;
    --height: 95%;
  }

  ion-content.custom-modal  {
    width: 100%;
    height: 100%;
  }  
}

// --- ALERT --- //
:root {
  --ion-color-violet-500: #7c3aed; /* Colore "violet-500" di Tailwind */
  --ion-color-violet-500-rgb: 124, 58, 237; /* Per effetti opzionali come opacità */
}

ion-alert.success,
ion-alert.danger,
ion-alert.nc {
  --backdrop-opacity: 0.7;
}

.success .alert-button-group,
.danger .alert-button-group,
.nc .alert-button-group {
  padding: 8px;
}

button.alert-button.alert-button-confirm-success {
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

button.alert-button.alert-button-confirm-danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-success-contrast);
}

button.alert-button.alert-button-confirm-nc {
  background-color: var(--ion-color-violet-500);
  color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm-success,
.md button.alert-button.alert-button-confirm-danger,
.md button.alert-button.alert-button-confirm-nc {
  border-radius: 4px;
}

.ios .success button.alert-button,
.ios .danger button.alert-button,
.ios .nc button.alert-button{
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm-success,
.ios button.alert-button.alert-button-confirm-danger,
.ios button.alert-button.alert-button-confirm-nc {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

